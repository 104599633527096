<template>
  <div>
    <!-- heads -->
    <div class="heads box-bg">
      <!-- 'https://oss.sokogate.com/'+  -->
      <el-image
        :src="storeData_banner + '?x-oss-process=style/w720'"
        style="width: 100%; height: 110px; border-radius: 10px"
        class="fl"
      ></el-image>
      <MaterialSelector
        :count="1"
        type="image"
        style="margin-left: 50px"
        @receiver="handleSelectionResult($event, 1)"
      >
        <template v-slot:custom-open-button>
          <div class="button">
            <el-button class="button1">{{
              $t("shop.replacePicture")
            }}</el-button>
          </div>
        </template>
      </MaterialSelector>
    </div>
    <!-- upload -->
    <el-row class="upload">
      <el-col :span="6">
        <span
          >{{ $t("shop.storeHeaderBanner") }},
          {{ $t("shop.uploadSize") }}：1440px*110px</span
        >
      </el-col>
    </el-row>

    <!-- main -->
    <div class="main">
      <el-image
        :src="swiper_image + '?x-oss-process=style/w720'"
        style="width: 100%; height: 330px; border-radius: 10px"
        class="fl"
      ></el-image>
      <MaterialSelector
        :count="1"
        type="image"
        style="margin-left: 50px"
        @receiver="handleSelectionResult($event, 2)"
      >
        <template v-slot:custom-open-button>
          <div class="button">
            <el-button class="button1">{{ $t("common.edit") }}</el-button>
          </div>
        </template>
      </MaterialSelector>
    </div>
    <!-- upload -->
    <el-row class="upload">
      <el-col :span="6">
        <span
          >{{ $t("shop.storeSwiperImages") }},
          {{ $t("shop.uploadSize") }}：1440px*330px</span
        >
      </el-col>
    </el-row>

    <!-- buttom -->
    <el-row class="mt bottom">
      <el-col :span="12">
        <el-image
          :src="storeData_aboutImage + '?x-oss-process=style/w256'"
          style="
            width: 550px;
            height: 330px;
            border-radius: 10px;
            border: 1px solid red;
          "
          class="fl"
        ></el-image>
        <MaterialSelector
          :count="1"
          type="image"
          style="margin-left: 50px"
          @receiver="handleSelectionResult($event, 3)"
        >
          <template v-slot:custom-open-button>
            <div class="button">
              <el-button class="button1">{{
                $t("shop.replacePicture")
              }}</el-button>
            </div>
          </template>
        </MaterialSelector>
      </el-col>
    </el-row>
    <el-row class="upload">
      <el-col :span="6">
        <span
          >{{ $t("shop.storeAboutImage") }},
          {{ $t("shop.uploadSize") }}：560px*430px</span
        >
      </el-col>
    </el-row>

    <el-button class="btn" @click="preservation">{{
      $t("store.preservation")
    }}</el-button>
  </div>
</template>

<script>
import { postData, renderData } from "../../helper/api";
import MaterialSelector from "@/components/MaterialSelector";
export default {
  components: {
    MaterialSelector,
  },
  data() {
    return {
      // storeData: [],
      swiper_image: "",
      storeData_banner: "",
      storeData_aboutImage: "",
    };
  },
  created() {
    // this.store();
    this.getStoreData();
  },
  methods: {
    // 图片选择器
    handleSelectionResult(result, type) {
      console.log("result, type:", result, type);
      if (type === 1) {
        // 主图
        this.storeData_banner = result.filename;
      }
      if (type === 2) {
        this.swiper_image = result.filename;
      }
      if (type === 3) {
        this.storeData_aboutImage = result.filename;
      }
    },
    // 获取图片
    getStoreData() {
      renderData("GET", "storeDecoration").then((res) => {
        this.storeData = res.data;
        console.log("this.storeData:", this.storeData);
        this.storeData_banner = this.storeData.banner;
        this.swiper_image = this.storeData.swipers[0].img;
        this.storeData_aboutImage = this.storeData.aboutImage;
      });
    },
    // 保存
    preservation() {
      postData("PUT", "vendor/storeDecoration", {
        banner: this.storeData_banner,
        aboutImage: this.storeData_aboutImage,
        swiperImages: [{ title: "", url: "", img: "" }],
      });
    },
  },
};
</script>

<style scoped>
.heads {
  height: 110px;
  position: relative;
  opacity: 0.9;
  border: 1px red solid;
}
.heads .button {
  position: absolute;
  right: 0;
  font-size: 13px;
  font-weight: 400;
  margin-right: 10px;
  margin-top: 5px;
}
.upload {
  font-size: 13px;
  height: 12px;
  margin-top: 12px;
  font-weight: 700;
  color: red;
  margin-bottom: 30px;
}
.main {
  width: 100%;
  height: 329px;
  margin-top: 20px;
  border: 1px red solid;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.main .button {
  position: absolute;
  right: 0;
  font-size: 13px;
  font-weight: 400;
  margin-right: 10px;
  margin-top: 5px;
}
.main img {
  width: 100%;
  height: 100%;
}
.upload1 {
  font-size: 10px;
  height: 12px;
  margin-top: 190px;
  font-weight: 700;
}
.bottom {
  position: relative;
}
.about_image {
  border: 1px red solid;
  border-radius: 10px;
  width: 560px;
  height: 430px;
}
.bottom .button {
  position: absolute;
  top: 5px;
  margin-left: 400px;
}

.swiper_image {
  height: auto;
  overflow: hidden;
}
.swiper_image .image {
  width: 200px;
  height: 100px;
  border: 1px #cccccc solid;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  float: left;
  margin-top: 10px;
  position: relative;
}
.swiper_image .image .el-icon-close {
  position: absolute;
  border: 1px red solid;
  margin-left: 180px;
  margin-top: 5px;
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;
}
.swiper_image .image img {
  width: 200px;
  height: 100px;
}
.dialog-footer {
  margin-top: 20px;
  text-align: right;
}
.btn {
  width: 218px;
  height: 51px;
  background: #ff0a0a;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  margin-left: 20px;
}
</style>